import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Descriptions,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Switch,
  Table,
  Timeline,
  TreeSelect,
  Typography,
} from 'antd'
import {sysModel} from '@/store/models/Sys'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import DividerLine from '@/pages/property/component/DividerLine'
import {Album} from '@/components/Album'
import {getImg} from '@/utils/util'
import moment from 'moment'
import {userModel} from '@/store/models/User'
import {sysUserModel} from '@/store/models/SysUser'
import {stewardModel} from '@/store/models/Steward'
import currency from 'currency.js'

interface StewardApprovalDetailProps {
  approvalOrderId: string
  onClose: () => void
  approval: boolean
  onSuccess: () => void
}

export default function StewardApprovalDetail({
  approvalOrderId,
  onClose,
  approval,
  onSuccess,
}: StewardApprovalDetailProps) {
  const userInfo = userModel.useData(data => data.userInfo)
  const [detail, setDetail] = useState({} as ApprovalOrderDetailVO)
  const [process, setProcess] = useState({} as ApprovalProcessDetail)
  const [open, setOpen] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [contentCode, setContentCode] = useState<string[]>([])

  const approvalOrderStatus = useCodeSync('approval.order.status')
  const positionLevelArr = useCodeArraySync('sys.user.steward.position.level')
  const [tree, setTree] = useState<SysOrgTreeNodeVO[]>([])
  const [selfTree, setSelfTree] = useState<SysOrgTreeNodeVO[]>([])

  useEffect(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: '00'} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      setTree(data)
    })
    sysModel
      .sysOrgTree({fetchUser: false, orgIdLike: userInfo?.orgId} as SysOrgTreeQueryDTO)
      .then(({response: {data}}) => {
        setSelfTree(data)
      })
  }, [])

  const searchDetail = useCallback(() => {
    if (approvalOrderId) {
      sysModel.approvalOrderDetail({approvalOrderId}).then(({response: {data}}) => {
        setDetail(data)
        setProcess(data.processList.find(item => item.approvalUserId === userInfo?.userId))
      })
    }
  }, [approvalOrderId])

  useEffect(() => {
    searchDetail()
  }, [searchDetail])

  const [userList, setUserList] = useState<SysUserVO[]>([])
  const [warehouseUserList, setWarehouseUserList] = useState<SysUserSimpleVO[]>([])
  const [numberAxList, setNumberAxList] = useState<PrivateNumberBindAxVO[]>([])
  const [openAppList, setOpenAppList] = useState<StewardOpenAppVO[]>([])
  // 城市列表
  const [cityList, setCityList] = useState<CityDataProps[]>([])
  const [planList, setPlanList] = useState<StewardSalaryPlanVO[]>([])

  useEffect(() => {
    sysUserModel
      .sysUserList({status: '1', roleId: '', pageNum: 1, pageSize: 10, pageable: false} as SysListDTO)
      .then(({response: {data}}) => {
        setUserList(data)
      })

    sysUserModel.sysUserListUserByPermissionOfWarehouse().then(({response: {data}}) => {
      setWarehouseUserList(data)
    })

    sysUserModel
      .privateNumberAxList({allocated: '0', pageable: false, pageNum: 1, pageSize: 1} as PrivateNumberBindAxListDTO)
      .then(({response: {data}}) => {
        setNumberAxList(data)
      })

    stewardModel
      .stewardOpenAppList({appStatus: 1, pageSize: 1, pageable: false, pageNum: 1} as StewardAppOpenListDTO)
      .then(({response: {data}}) => {
        setOpenAppList(data)
      })

    commonModel.queryCityCode({type: '2', parentCode: ''}).then(({response: {data}}) => {
      setCityList(data)
    })
  }, [])

  // 查询绩效方案
  const searchPlan = useCallback((params: Partial<StewardSalaryPlanListDTO>) => {
    sysModel
      .stewardSalaryPlanList({
        pageNum: 1,
        pageSize: 1,
        pageable: false,
        status: '1',
        ...params,
      } as StewardSalaryPlanListDTO)
      .then(({response: {data}}) => {
        setPlanList(data)
      })
  }, [])

  // function onSalaryChange(salary) {
  //   // 1.按薪资百分比 2.按薪资固定金额
  //   const performanceRule = form.getFieldValue('performanceRule')
  //   // 绩效计算规则基数
  //   const performanceRuleBase = form.getFieldValue('performanceRuleBase')
  //
  //   // 按薪资百分比
  //   if (performanceRule === '1' && !isNonInput(performanceRuleBase)) {
  //     // 绩效 = 综合薪资 * 按薪资百分比 * 0.01
  //     const _performance = salary * stringToNumber(performanceRuleBase) * 0.01
  //     // 底薪 = 综合薪资 - 绩效
  //     const _baseSalary = salary - _performance
  //
  //     form.setFieldsValue({baseSalary: numberToFractional(_baseSalary), performance: numberToFractional(_performance)})
  //
  //     updateProbationBaseSalary(numberToFractional(_baseSalary))
  //   }
  //
  //   if (performanceRule === '2' && !isNonInput(performanceRuleBase)) {
  //     // 按薪资固定金额
  //     const _performance = stringToNumber(performanceRuleBase)
  //     // 底薪 = 综合薪资 - 绩效
  //     const _baseSalary = salary - _performance
  //
  //     form.setFieldsValue({baseSalary: numberToFractional(_baseSalary), performance: numberToFractional(_performance)})
  //
  //     updateProbationBaseSalary(numberToFractional(_baseSalary))
  //   }
  // }

  /**
   * updateProbationBaseSalary 更新试用期绩效数据
   * @baseSalary 底薪
   * @performance 绩效
   */
  // function updateProbationBaseSalary(baseSalary, performance = form.getFieldValue('performance') || 0) {
  //   // 绩效计算规则-试用期[1.不打折 2.按绩效百分比打折 3.按绩效固定金额打折]
  //   const probationPerformanceRule = form.getFieldValue('probationPerformanceRule')
  //   if (!isNonInput(probationPerformanceRule)) {
  //     // 绩效计算规则基数-试用期
  //     const probationPerformanceRuleBase = form.getFieldValue('probationPerformanceRuleBase')
  //     if (!isNonInput(probationPerformanceRuleBase)) {
  //       // 2.按绩效百分比打折
  //       if (probationPerformanceRule === '2') {
  //         // 打折后的绩效 = 绩效 - （绩效 * 绩效百分比打折 * 0.01）
  //         const _probationPerformance = performance * stringToNumber(probationPerformanceRuleBase) * 0.01
  //         // 打折后的综合薪资 = 试用期底薪 + 打折后的绩效
  //         const _probationSalary = baseSalary + _probationPerformance
  //         form.setFieldsValue({
  //           probationSalary: numberToFractional(_probationSalary),
  //           probationPerformance: numberToFractional(_probationPerformance),
  //         })
  //       }
  //
  //       // 3.按绩效固定金额打折
  //       if (probationPerformanceRule === '3') {
  //         // 按绩效固定金额打折
  //         const _probationPerformance = performance - stringToNumber(probationPerformanceRuleBase)
  //         // 打折后的综合薪资 = 试用期底薪 + 打折后的绩效
  //         const _probationSalary = stringToNumber(baseSalary) + _probationPerformance
  //         form.setFieldsValue({
  //           probationSalary: numberToFractional(_probationSalary),
  //           probationPerformance: numberToFractional(_probationPerformance),
  //         })
  //       }
  //     }
  //
  //     form.setFieldsValue({probationBaseSalary: baseSalary})
  //   }
  // }

  function isNumber(value: any) {
    return typeof +value === 'number' && !isNaN(+value)
  }

  function updateAllowance() {
    const {
      probationSalary,
      probationBaseSalary,
      probationPerformance,
      salary,
      baseSalary,
      performance,
    } = form.getFieldsValue()
    if (isNumber(probationSalary) && isNumber(probationBaseSalary) && isNumber(probationPerformance)) {
      form.setFieldValue(
        'probationAllowance',
        currency(probationSalary).subtract(probationBaseSalary).subtract(probationPerformance).value
      )
    }
    if (isNumber(salary) && isNumber(baseSalary) && isNumber(performance)) {
      form.setFieldValue('allowance', currency(salary).subtract(baseSalary).subtract(performance).value)
    }
  }

  return (
    <Drawer open={!!approvalOrderId} destroyOnClose width={900} title={'审批详情'} onClose={() => onClose()}>
      <DividerLine title={'基本信息'} bottom={15} />
      <Descriptions column={2}>
        <Descriptions.Item label={'审批ID'}>{approvalOrderId}</Descriptions.Item>
        <Descriptions.Item label={'审批类型'}>{detail.configName}</Descriptions.Item>
        <Descriptions.Item label={'申请人'}>{detail.applyUserName}</Descriptions.Item>
        <Descriptions.Item label={'提交时间'}>{detail.insertTime}</Descriptions.Item>
        <Descriptions.Item label={'审批状态'}>{approvalOrderStatus[detail.approvalStatus]}</Descriptions.Item>
      </Descriptions>
      <DividerLine title={'申请信息'} bottom={15} />
      {detail.approvalStatus === '5' && (
        <Descriptions column={2}>
          <Descriptions.Item label={'撤销时间'}>{detail.repealTime}</Descriptions.Item>
          <Descriptions.Item label={'撤销原因'}>{detail.repealRemark}</Descriptions.Item>
        </Descriptions>
      )}
      {/* 管家工作交接内容 */}
      {detail.configId === '1' && <WorkHandoverDetail detail={detail.approvalStewardWordHandoverDTO} />}
      {/* 转单审批内容 */}
      {detail.configId === '2' && (
        <Descriptions column={2}>
          <Descriptions.Item label={'订单编号'}>{detail.approvalTransferOrderDTO?.orderId}</Descriptions.Item>
          <Descriptions.Item label={'接手管家'}>
            {detail.approvalTransferOrderDTO?.takeOverStewardName}
          </Descriptions.Item>
          <Descriptions.Item label={'转单原因'}>{detail.approvalTransferOrderDTO?.transferReason}</Descriptions.Item>
        </Descriptions>
      )}
      {/* 管家入职 */}
      {detail.configId === '3' && <StewardEntryDetail detail={detail.approvalStewardEntryDTO} />}
      {/* 新开站点 */}
      {detail.configId === '4' && <NewSiteDetail detail={detail.approvalNewWarehouseDTO} />}
      {/* 转正审批 */}
      {detail.configId === '5' && <BecomeWorker detail={detail.approvalStewardRegularDTO} />}
      {/* 龙湖报价 */}
      {detail.configId === '1000' && <LhQuotationDetail detail={detail.approvalStewardOrderQuotationDTO} />}
      {/* 贝壳报价 */}
      {detail.configId === '1001' && <BKQuotationDetail detail={detail.approvalStewardOrderQuotationBeikeDTO} />}
      {/* 管家调薪 */}
      {detail.configId === '9001' && <StewardSalaryDetail detail={detail.approvalStewardSalaryAdjustmentDTO} />}
      {/* 管家调动 */}
      {detail.configId === '9000' && <StewardMove detail={detail.approvalStewardTransferDTO} />}
      {/* 班休调整 */}
      {detail.configId === '9002' && <StewardSchedulingAdjustment detail={detail.approvalStewardRestAdjustmentDTO} />}
      <DividerLine
        title={'审批信息'}
        top={15}
        bottom={20}
        right={
          ['3', '4'].includes(detail.configId) &&
          approval &&
          detail.approvalStatus === '2' &&
          process?.approvalStatus === '2' && (
            <Button
              size={'large'}
              type={'link'}
              onClick={async () => {
                const {
                  response: {data},
                } = await sysModel.approvalOrderApprovalUserContent({approvalOrderId})
                const {configId, approvalStewardEntryDTO, approvalNewWarehouseDTO} = detail
                if (configId === '3') {
                  const planList = approvalStewardEntryDTO.planList
                  if (planList?.length) {
                    const {appId, cityCode} = planList[0]
                    form.setFieldsValue({cityCode})
                    searchPlan({appId, cityCode})
                  }
                  form.setFieldsValue({
                    ...approvalStewardEntryDTO,
                    salary: approvalStewardEntryDTO.salaryAndPerformance?.salary,
                    // performanceRule: approvalStewardEntryDTO.salaryAndPerformance?.performanceRule,
                    baseSalary: approvalStewardEntryDTO.salaryAndPerformance?.baseSalary,
                    performance: approvalStewardEntryDTO.salaryAndPerformance?.performance,
                    allowance: approvalStewardEntryDTO.salaryAndPerformance?.allowance,
                    // performanceRuleBase: approvalStewardEntryDTO.salaryAndPerformance?.performanceRuleBase,

                    probationSalary: approvalStewardEntryDTO.salaryAndPerformance?.probationSalary,
                    // probationPerformanceRule: approvalStewardEntryDTO.salaryAndPerformance?.probationPerformanceRule,
                    probationBaseSalary: approvalStewardEntryDTO.salaryAndPerformance?.probationBaseSalary,
                    probationPerformance: approvalStewardEntryDTO.salaryAndPerformance?.probationPerformance,
                    probationAllowance: approvalStewardEntryDTO.salaryAndPerformance?.probationAllowance,
                    // probationPerformanceRuleBase:
                    //   approvalStewardEntryDTO.salaryAndPerformance?.probationPerformanceRuleBase,
                    probationMealSubsidy: approvalStewardEntryDTO.salaryAndPerformance?.probationMealSubsidy,
                    mealSubsidy: approvalStewardEntryDTO.salaryAndPerformance?.mealSubsidy,
                    configId,
                  })
                }
                if (configId === '4') {
                  const orgList = approvalNewWarehouseDTO?.orgList || []
                  form.setFieldsValue({
                    ...approvalNewWarehouseDTO,
                    configId,
                    warehouseOrgId: orgList[0]?.key,
                    warehouseOrgName: orgList[0]?.value,
                  })
                }

                setContentCode(data)
                setOpen(true)
              }}
            >
              审批
            </Button>
          )
        }
      />
      <Timeline>
        {detail.processList?.map((item, index) => {
          return (
            <Timeline.Item key={index} dot={<Dot active={index === 0} />}>
              <div>
                <Typography.Text style={{color: '#222832', fontWeight: 600}}>
                  {item.approvalUserName}
                  {approvalOrderStatus[item.approvalStatus]}
                </Typography.Text>
              </div>
              {item.approvalRemark && (
                <div>
                  <Typography.Text style={{marginTop: '10px', color: '#909399'}}>{item.approvalRemark}</Typography.Text>
                </div>
              )}
              {['3', '4'].includes(item.approvalStatus) && (
                <div>
                  <Typography.Text style={{marginTop: '10px', color: '#909399'}}>{item.approvalTime}</Typography.Text>
                </div>
              )}
            </Timeline.Item>
          )
        })}
      </Timeline>

      {/*  审批  */}
      <Modal
        title={'审批'}
        open={open}
        centered
        width={700}
        destroyOnClose
        afterClose={() => {
          setPlanList([])
          form.resetFields()
        }}
        onOk={() => form.submit()}
        onCancel={() => setOpen(false)}
      >
        <Form
          form={form}
          labelCol={{flex: '130px'}}
          wrapperCol={{span: 15}}
          onFinish={async () => {
            const {status, approvalRemark, configId, ...values} = form.getFieldsValue(true)
            await sysModel.approvalOrderStatusFlow({
              status,
              approvalOrderId,
              approvalRemark,
              paramsFlag: status === '3',
              approvalStewardEntryDTO:
                configId === '3'
                  ? {
                      ...values,
                      salaryAndPerformance: {
                        salary: values.salary,
                        baseSalary: values.baseSalary,
                        performance: values.performance,
                        allowance: values.allowance,
                        mealSubsidy: values.mealSubsidy,
                        // performanceRule: values.performanceRule,
                        // performanceRuleBase: values.performanceRuleBase,
                        probationSalary: values.probationSalary,
                        probationBaseSalary: values.probationBaseSalary,
                        probationPerformance: values.probationPerformance,
                        probationAllowance: values.probationAllowance,
                        probationMealSubsidy: values.probationMealSubsidy,
                        // probationPerformanceRule: values.probationPerformanceRule,
                        // probationPerformanceRuleBase: values.probationPerformanceRuleBase,
                      } as ApprovalSalaryAndPerformanceDTO,
                    }
                  : {},
              approvalNewWarehouseDTO:
                configId === '4'
                  ? {...values, orgList: [{key: values.warehouseOrgId, value: values.warehouseOrgName}]}
                  : {},
            } as ApprovalOrderOperationDTO)
            searchDetail()
            onSuccess?.()
            setOpen(false)
          }}
        >
          <Form.Item label={'审批结果'} rules={[{required: true, message: '请选择审批结果'}]} name={'status'}>
            <Radio.Group>
              <Radio value={'3'}>同意</Radio>
              <Radio value={'4'}>拒绝</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.status !== next.status}>
            {f => {
              const status = f.getFieldValue('status')
              return (
                <>
                  {/* 管家入职 */}
                  {status === '3' && (
                    <>
                      {contentCode.includes('31') && (
                        <Form.Item
                          rules={[{required: true, message: '请选择所属组织'}]}
                          label={'所属组织'}
                          name={'orgId'}
                        >
                          <TreeSelect
                            onChange={(_, label) => {
                              form.setFieldsValue({orgName: label?.[0]})
                            }}
                            placeholder={'请选择'}
                            allowClear
                            treeData={tree}
                          />
                        </Form.Item>
                      )}
                      {contentCode.includes('39') && (
                        <Form.Item
                          // rules={[{required: true, message: '请选择所属组织'}]}
                          label={'运营组织'}
                          name={'orgV2List'}
                          getValueProps={value => ({value: value ? value.map(item => item.key) : undefined})}
                          getValueFromEvent={(value, label) => {
                            return value?.map((item, index) => ({key: item, value: label?.[0]}))
                          }}
                        >
                          <TreeSelect multiple placeholder={'请选择'} allowClear treeData={tree} />
                        </Form.Item>
                      )}
                      {contentCode.includes('32') && (
                        <Form.Item label={'带教老师'} name={'teacherId'}>
                          <Select
                            placeholder={'请选择'}
                            showSearch
                            filterOption={(inputValue: string, option) => {
                              return option.name?.indexOf(inputValue) > -1
                            }}
                            onChange={value => {
                              const {name, mobile} = userList.find(item => item.userId === value)
                              form.setFieldsValue({teacherName: name, mobile})
                            }}
                            options={userList.filter(item => item.roleIdList?.includes('steward'))}
                            fieldNames={{label: 'name', value: 'userId'}}
                            allowClear
                          />
                        </Form.Item>
                      )}
                      {contentCode.includes('33') && (
                        <Form.Item
                          label={'虚拟号码'}
                          rules={[{required: true, message: '请选择虚拟号码'}]}
                          name={'virtualNumber'}
                        >
                          <Select
                            placeholder={'请选择'}
                            options={numberAxList}
                            fieldNames={{label: 'privateNum', value: 'privateNum'}}
                            allowClear
                          />
                        </Form.Item>
                      )}
                      {contentCode.includes('34') && (
                        <Form.Item
                          label={'服务标签'}
                          rules={[{required: true, message: '请选择服务标签'}]}
                          name={'serviceAppList'}
                          getValueFromEvent={value => {
                            const {appId, appName} = openAppList.find(item => item.appId === value) ?? {}
                            return [{key: appId, value: appName}]
                          }}
                          getValueProps={value => ({value: value ? value[0].key : undefined})}
                        >
                          <Select
                            placeholder={'请选择'}
                            options={openAppList}
                            onChange={value => {
                              if (value) {
                                const cityCode = form.getFieldValue('cityCode')
                                if (cityCode) {
                                  searchPlan({appId: value, cityCode})
                                }
                              } else {
                                setPlanList([])
                              }
                              form.setFieldsValue({planList: []})
                            }}
                            fieldNames={{label: 'appName', value: 'appId'}}
                            allowClear
                          />
                        </Form.Item>
                      )}
                      {contentCode.includes('35') && (
                        <Form.Item
                          label={'岗位等级'}
                          rules={[{required: true, message: '请选择岗位等级'}]}
                          name={'positionLevel'}
                        >
                          <Select
                            placeholder={'请选择'}
                            options={positionLevelArr}
                            fieldNames={{value: 'id'}}
                            allowClear
                          />
                        </Form.Item>
                      )}
                      {contentCode.includes('36') && (
                        <Form.Item noStyle shouldUpdate>
                          {() => {
                            // const {
                            //   salary,
                            //   performanceRule,
                            //   baseSalary,
                            //   probationPerformanceRule,
                            //   probationSalary = 0,
                            //   performance = 0,
                            //   probationBaseSalary = 0,
                            //   probationPerformance = 0,
                            // } = f.getFieldsValue(true)
                            return (
                              <>
                                {/*<Form.Item
                                  label={'综合薪资(元)'}
                                  rules={[{required: true, message: '请输入综合薪资(元)'}]}
                                  name={'salary'}
                                >
                                  <InputNumber
                                    addonAfter={'元'}
                                    onChange={value => {
                                      if (value) {
                                        onSalaryChange(value)
                                      } else {
                                        f.setFieldsValue({
                                          salary: undefined,
                                          baseSalary: undefined,
                                          performance: undefined,
                                          probationSalary: undefined,
                                          probationBaseSalary: undefined,
                                          probationPerformance: undefined,
                                        })
                                      }
                                    }}
                                    placeholder={'请输入'}
                                    min={0}
                                    step={0.01}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={'绩效计算规则'}
                                  name={'performanceRule'}
                                  rules={[{required: true, message: '请选择绩效计算规则'}]}
                                >
                                  <Radio.Group
                                    onChange={() => {
                                      form.setFieldsValue({
                                        performanceRuleBase: undefined,
                                        baseSalary: undefined,
                                        performance: undefined,
                                      })
                                    }}
                                  >
                                    <Radio value={'1'}>按薪资百分比</Radio>
                                    <Radio value={'2'}>按薪资固定金额</Radio>
                                  </Radio.Group>
                                </Form.Item>
                                {performanceRule === '1' && (
                                  <Form.Item
                                    label={'按薪资百分比(%)'}
                                    rules={[{required: true, message: '请输入'}]}
                                    name={'performanceRuleBase'}
                                  >
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      onChange={value => {
                                        // const performance = (salary * value) / 100
                                        // const baseSalary = salary - performance
                                        // f.setFieldsValue({
                                        //   baseSalary,
                                        //   performance,
                                        //   probationBaseSalary: baseSalary,
                                        // })
                                        // 按薪资百分比
                                        // 绩效 = 综合薪资 * 按薪资百分比 * 0.01
                                        const _performance = salary * stringToNumber(value) * 0.01
                                        // 底薪 = 综合薪资 - 绩效
                                        const _baseSalary = salary - _performance
                                        f.setFieldsValue({
                                          baseSalary: numberToFractional(_baseSalary),
                                          performance: numberToFractional(_performance),
                                        })
                                        updateProbationBaseSalary(
                                          numberToFractional(_baseSalary),
                                          numberToFractional(_performance)
                                        )
                                      }}
                                      addonAfter={'%'}
                                      placeholder={'请输入'}
                                    />
                                  </Form.Item>
                                )}
                                {performanceRule === '2' && (
                                  <Form.Item
                                    label={'按薪资固定金额(元)'}
                                    rules={[{required: true, message: '请输入'}]}
                                    name={'performanceRuleBase'}
                                  >
                                    <InputNumber
                                      min={0}
                                      step={0.01}
                                      onChange={value => {
                                        // const baseSalary = salary - value
                                        // f.setFieldsValue({
                                        //   baseSalary: baseSalary,
                                        //   performance: value,
                                        //   probationBaseSalary: baseSalary,
                                        // })

                                        // 按薪资固定金额
                                        const _performance = stringToNumber(value)
                                        // 底薪 = 综合薪资 - 绩效
                                        const _baseSalary = salary - _performance
                                        f.setFieldsValue({
                                          baseSalary: numberToFractional(_baseSalary),
                                          performance: numberToFractional(_performance),
                                        })
                                        updateProbationBaseSalary(
                                          numberToFractional(_baseSalary),
                                          numberToFractional(_performance)
                                        )
                                      }}
                                      addonAfter={'元'}
                                      placeholder={'请输入'}
                                    />
                                  </Form.Item>
                                )}
                                <Form.Item label={' '} colon={false}>
                                  <span>{`薪资：${salary ?? '0'}元，底薪：${baseSalary ?? 0}元，绩效：${
                                    performance ?? 0
                                  }元`}</span>
                                </Form.Item>
                                <Form.Item
                                  label={'试用期是否打折'}
                                  name={'probationPerformanceRule'}
                                  rules={[{required: true, message: '请选择'}]}
                                >
                                  <Select
                                    options={[
                                      {label: '不打折', value: '1'},
                                      {label: '按绩效百分比打折', value: '2'},
                                      {label: '按绩效固定金额打折', value: '3'},
                                    ]}
                                    onChange={async value => {
                                      // await form.validateFields([''])
                                      // const {performance, salary} = form.getFieldsValue(true)
                                      // form.setFieldsValue({
                                      //   probationPerformanceRuleBase: undefined,
                                      //   probationSalary: value === '1' ? salary : 0,
                                      //   probationPerformance: value === '1' ? performance : 0,
                                      // })

                                      if (value === '1') {
                                        // 不打折：试用期和实际是一样的
                                        form.setFieldsValue({
                                          probationSalary: salary,
                                          probationBaseSalary: baseSalary,
                                          probationPerformance: performance,
                                        })
                                      } else {
                                        // 打折的话，底薪是固定的
                                        form.setFieldsValue({
                                          // 选择打折方式时，清空试用期薪资，试用期绩效，试用期绩效基数
                                          probationSalary: undefined,
                                          probationBaseSalary: baseSalary,
                                          probationPerformance: undefined,
                                          probationPerformanceRuleBase: undefined,
                                        })
                                      }
                                    }}
                                    placeholder={'请选择'}
                                    allowClear
                                  />
                                </Form.Item>
                                {probationPerformanceRule === '2' && (
                                  <>
                                    <Form.Item
                                      label={' '}
                                      rules={[{required: true, message: '请输入'}]}
                                      colon={false}
                                      name={'probationPerformanceRuleBase'}
                                    >
                                      <InputNumber
                                        min={0}
                                        max={100}
                                        onChange={value => {
                                          // const probationPerformance = (performance * value) / 100
                                          // const probationSalary = probationBaseSalary + probationPerformance
                                          // form.setFieldsValue({probationSalary, probationPerformance})

                                          // 按绩效百分比打折
                                          // 打折后的绩效 = 绩效 - （绩效 * 绩效百分比打折 * 0.01）
                                          const _probationPerformance = performance * stringToNumber(value) * 0.01
                                          // 打折后的综合薪资 = 试用期底薪 + 打折后的绩效
                                          const _probationSalary =
                                            stringToNumber(probationBaseSalary) + _probationPerformance
                                          f.setFieldsValue({
                                            probationSalary: numberToFractional(_probationSalary),
                                            probationPerformance: numberToFractional(_probationPerformance),
                                          })
                                        }}
                                        addonAfter={'%'}
                                        placeholder={'请输入'}
                                      />
                                    </Form.Item>
                                  </>
                                )}
                                {probationPerformanceRule === '3' && (
                                  <Form.Item
                                    label={' '}
                                    rules={[{required: true, message: '请输入'}]}
                                    colon={false}
                                    name={'probationPerformanceRuleBase'}
                                  >
                                    <InputNumber
                                      min={0}
                                      step={0.01}
                                      onChange={value => {
                                        // const probationPerformance = performance - value > 0 ? performance - value : 0
                                        // const probationSalary = probationBaseSalary + probationPerformance
                                        // form.setFieldsValue({probationSalary, probationPerformance})

                                        // 按绩效固定金额打折
                                        const _probationPerformance = performance - stringToNumber(value)
                                        // 打折后的综合薪资 = 试用期底薪 + 打折后的绩效
                                        const _probationSalary =
                                          stringToNumber(probationBaseSalary) + _probationPerformance
                                        f.setFieldsValue({
                                          probationSalary: numberToFractional(_probationSalary),
                                          probationPerformance: numberToFractional(_probationPerformance),
                                        })
                                      }}
                                      addonAfter={'元'}
                                      placeholder={'请输入'}
                                    />
                                  </Form.Item>
                                )}
                                <Form.Item label={' '} colon={false}>
                                  <span>{`薪资：${probationSalary ?? 0}元，底薪：${probationBaseSalary ?? 0}元，绩效：${
                                    probationPerformance ?? 0
                                  }元`}</span>
                                </Form.Item>*/}
                                <Form.Item label={'试用期薪资'} name={'probationSalary'} rules={[{required: true}]}>
                                  <InputNumber
                                    style={{width: '100%'}}
                                    placeholder={'请输入'}
                                    onChange={updateAllowance}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={'基础工资'}
                                  name={'probationBaseSalary'}
                                  rules={[{required: true}]}
                                  initialValue={3000}
                                >
                                  <InputNumber
                                    style={{width: '100%'}}
                                    placeholder={'请输入'}
                                    onChange={updateAllowance}
                                  />
                                </Form.Item>
                                <Form.Item label={'绩效工资'} name={'probationPerformance'} rules={[{required: true}]}>
                                  <InputNumber
                                    style={{width: '100%'}}
                                    placeholder={'请输入'}
                                    onChange={updateAllowance}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={'岗位津贴'}
                                  name={'probationAllowance'}
                                  rules={[
                                    {required: true},
                                    {
                                      type: 'number',
                                      min: 0,
                                      message: '试用期岗位津贴小于0或试用期薪资计算错误，请重新输入正确薪资',
                                    },
                                  ]}
                                >
                                  <InputNumber style={{width: '100%'}} placeholder={'岗位津贴'} disabled />
                                </Form.Item>
                                <Form.Item label={'转正薪资'} name={'salary'} rules={[{required: true}]}>
                                  <InputNumber
                                    style={{width: '100%'}}
                                    placeholder={'请输入'}
                                    onChange={updateAllowance}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={'基础工资'}
                                  name={'baseSalary'}
                                  rules={[{required: true}]}
                                  initialValue={3000}
                                >
                                  <InputNumber
                                    style={{width: '100%'}}
                                    placeholder={'请输入'}
                                    onChange={updateAllowance}
                                  />
                                </Form.Item>
                                <Form.Item label={'绩效工资'} name={'performance'} rules={[{required: true}]}>
                                  <InputNumber
                                    style={{width: '100%'}}
                                    placeholder={'请输入'}
                                    onChange={updateAllowance}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={'岗位津贴'}
                                  name={'allowance'}
                                  rules={[
                                    {required: true},
                                    {
                                      type: 'number',
                                      min: 0,
                                      message: '岗位津贴小于0或薪资计算错误，请重新输入正确薪资',
                                    },
                                  ]}
                                >
                                  <InputNumber style={{width: '100%'}} placeholder={'岗位津贴'} disabled />
                                </Form.Item>

                                <Form.Item label={'餐补'} style={{marginBottom: 0}}>
                                  <Space>
                                    <Form.Item
                                      label={'试用期'}
                                      name={'probationMealSubsidy'}
                                      rules={[{required: true, message: '请输入试用期餐补'}]}
                                      colon={false}
                                    >
                                      <InputNumber min={0} step={0.01} addonAfter={'元/天'} />
                                    </Form.Item>
                                    <Form.Item
                                      label={'转正后'}
                                      name={'mealSubsidy'}
                                      rules={[{required: true, message: '请输入转正后餐补'}]}
                                      colon={false}
                                    >
                                      <InputNumber min={0} step={0.01} addonAfter={'元/天'} />
                                    </Form.Item>
                                  </Space>
                                </Form.Item>
                              </>
                            )
                          }}
                        </Form.Item>
                      )}
                      {contentCode.includes('38') && (
                        <>
                          <Form.Item label={'绩效方案城市'} name={'cityCode'}>
                            <Select
                              options={cityList}
                              fieldNames={{label: 'name', value: 'code'}}
                              placeholder={'请选择城市'}
                              showSearch
                              filterOption={(inputValue, option: any) => {
                                return option.name?.indexOf(inputValue) > -1
                              }}
                              onChange={async value => {
                                if (value) {
                                  const serviceAppList = form.getFieldValue('serviceAppList')
                                  if (serviceAppList?.length) {
                                    searchPlan({cityCode: value, appId: serviceAppList[0].key})
                                  }
                                } else {
                                  setPlanList([])
                                }
                                form.setFieldsValue({planList: []})
                              }}
                              allowClear
                            />
                          </Form.Item>
                          <Form.Item
                            label={'绩效方案'}
                            rules={[{required: true, message: '请选择绩效方案'}]}
                            name={'planList'}
                            getValueFromEvent={value => {
                              const {appId, appName, applyBeginDate, applyEndDate, planId, planName} =
                                planList.find(item => item.planId === value) ?? {}
                              const cityCode = form.getFieldValue('cityCode')
                              const cityName = cityList?.find(item => item.code === cityCode)?.name
                              return [
                                {appId, appName, applyBeginDate, applyEndDate, cityCode, cityName, planId, planName},
                              ]
                            }}
                            getValueProps={value => {
                              return {value: value?.length ? value[0].planId : undefined}
                            }}
                          >
                            <Select
                              options={planList}
                              fieldNames={{label: 'planName', value: 'planId'}}
                              placeholder={'请先选择绩效方案城市'}
                              showSearch
                              filterOption={(inputValue, option: any) => {
                                return option.planName?.indexOf(inputValue) > -1
                              }}
                              allowClear
                            />
                          </Form.Item>
                        </>
                      )}
                      {contentCode.includes('37') && (
                        <Form.Item shouldUpdate noStyle>
                          {f => {
                            const serviceAppList = f.getFieldValue('serviceAppList') ?? []
                            return (
                              <Form.Item
                                name={'beikeId'}
                                label={'贝壳用户ID'}
                                rules={[
                                  {
                                    required: serviceAppList.some(item => item.key === 'BEIKE'),
                                    message: '请输入贝壳用户ID',
                                  },
                                ]}
                              >
                                <Input placeholder={'请输入'} />
                              </Form.Item>
                            )
                          }}
                        </Form.Item>
                      )}

                      {/*  新开站点审批内容  */}
                      {contentCode.includes('42') && (
                        <Form.Item
                          rules={[{required: true, message: '请选择仓库关联组织'}]}
                          label={'仓库关联组织'}
                          name={'warehouseOrgId'}
                        >
                          <TreeSelect
                            placeholder={'请选择'}
                            onChange={(_, label) => {
                              form.setFieldsValue({warehouseOrgName: label?.[0]})
                            }}
                            treeDefaultExpandAll
                            allowClear
                            treeData={selfTree}
                          />
                        </Form.Item>
                      )}
                      {contentCode.includes('43') && (
                        <Form.Item
                          rules={[{required: true, message: '请选择人员管理组织'}]}
                          label={'人员管理组织'}
                          name={'personnelOrgId'}
                        >
                          <TreeSelect
                            treeDefaultExpandAll
                            onChange={(_, label) => {
                              form.setFieldsValue({personnelOrgName: label?.[0]})
                            }}
                            placeholder={'请选择'}
                            allowClear
                            treeData={selfTree}
                          />
                        </Form.Item>
                      )}
                      {contentCode.includes('44') && (
                        <Form.Item
                          rules={[{required: true, message: '请选择站点管理人'}]}
                          label={'站点管理人'}
                          name={'warehouseManagerId'}
                          getValueFromEvent={(value, label) => {
                            form.setFieldsValue({warehouseManagerName: label?.name})
                            return value
                          }}
                        >
                          <Select
                            placeholder={'请选择'}
                            showSearch
                            filterOption={(inputValue: string, option) => {
                              return option.name?.indexOf(inputValue) > -1
                            }}
                            options={warehouseUserList}
                            fieldNames={{label: 'name', value: 'userId'}}
                            allowClear
                          />
                        </Form.Item>
                      )}
                      {contentCode.includes('45') && (
                        <Form.Item
                          rules={[{required: true, message: '请选择房源接管人'}]}
                          label={'房源接管人'}
                          name={'propertyTakeOverId'}
                          getValueFromEvent={(value, label) => {
                            form.setFieldsValue({propertyTakeOverName: label?.name})
                            return value
                          }}
                        >
                          <Select
                            placeholder={'请选择'}
                            showSearch
                            filterOption={(inputValue: string, option) => {
                              return option.name?.indexOf(inputValue) > -1
                            }}
                            options={userList.filter(item => item.roleIdList?.includes('steward'))}
                            fieldNames={{label: 'name', value: 'userId'}}
                            allowClear
                          />
                        </Form.Item>
                      )}
                    </>
                  )}
                  <Form.Item
                    label={'审批备注'}
                    name={'approvalRemark'}
                    rules={[{required: status === '4', message: '请输入审批备注'}]}
                  >
                    <Input.TextArea placeholder={'请输入'} maxLength={200} showCount rows={3} />
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>
        </Form>
      </Modal>
    </Drawer>
  )
}

// 管家工作交接内容 configId === ‘1’
const WorkHandoverDetail = React.memo(({detail}: {detail: ApprovalStewardWordHandoverDTO}) => {
  return (
    <Descriptions column={2}>
      <Descriptions.Item label={'离职管家'}>{detail?.resignStewardName}</Descriptions.Item>
      <Descriptions.Item label={'负责站点'}>{detail?.orgName}</Descriptions.Item>
      <Descriptions.Item label={'离职日期'}>{detail?.resignDate}</Descriptions.Item>
      <Descriptions.Item label={'离职原因'}>{detail?.resignReason}</Descriptions.Item>
      <Descriptions.Item label={'遗留物品处理'}>
        {{1: '归还', 2: '转移'}[detail?.goodsDispose] || '/'}
      </Descriptions.Item>
      {detail.goodsDispose === '1' && (
        <Descriptions.Item label={'物品归还站点'}>{detail?.goodsTakeOverWarehouseName || '/'}</Descriptions.Item>
      )}
      {detail.goodsDispose === '2' && (
        <Descriptions.Item label={'物品接手管家'}>{detail?.goodsTakeOverStewardName ?? '/'}</Descriptions.Item>
      )}
      <Descriptions.Item label={'已绑房源处理'}>
        {{1: '解绑', 2: '转绑'}[detail?.propertyDispose] || '/'}
      </Descriptions.Item>
      {detail?.propertyDispose === '2' && (
        <Descriptions.Item label={'房源接手管家'}>{detail?.propertyTakeOverStewardName ?? '/'}</Descriptions.Item>
      )}
      <Descriptions.Item label={'工单接手管家'}>{detail?.workOrderTakeOverStewardName ?? '/'}</Descriptions.Item>
      {!!detail?.warehouseManagerList?.length &&
        detail.warehouseManagerList.map((item, index) => (
          <Descriptions.Item key={index} label={`${item.name}管理人`}>
            {item.managerName ?? '/'}
          </Descriptions.Item>
        ))}
      {!!detail?.warehousePropertyTakeOverList?.length &&
        detail.warehousePropertyTakeOverList.map((item, index) => (
          <Descriptions.Item key={index} label={`${item.name}房源接管`}>
            {item.managerName ?? '/'}
          </Descriptions.Item>
        ))}
      {!!detail?.propertyFirstStewardList?.length &&
        detail.propertyFirstStewardList.map((item, index) => (
          <Descriptions.Item key={index} label={`客户${item.customerName}的房源优先分配管家`}>
            {item.stewardList?.map(_item => _item.stewardName)?.join(',')}
          </Descriptions.Item>
        ))}
      <Descriptions.Item label={'解除虚拟号码'}>
        <Switch disabled checked={true} />
      </Descriptions.Item>
      <Descriptions.Item label={'解除师徒关系'}>
        <Switch disabled checked={true} />
      </Descriptions.Item>
      <Descriptions.Item label={'离职月是否结算工资'}>
        {{'1': '结算', '2': '不结算'}[detail?.salarySettlement]}
      </Descriptions.Item>
      <Descriptions.Item label={'申请备注'}>{detail?.applyRemark || '/'}</Descriptions.Item>
      <Descriptions.Item label={'申请附件'}>
        {detail?.attachList?.length ? (
          <Album disabled fileList={detail?.attachList.map(item => ({uid: item, url: getImg(item)}))}>
            {null}
          </Album>
        ) : (
          '/'
        )}
      </Descriptions.Item>
    </Descriptions>
  )
})

const restDayCode = {2: '星期一', 3: '星期二', 4: '星期三', 5: '星期四', 6: '星期五', 7: '星期六', 1: '星期日'}
// 管家入职
const StewardEntryDetail = React.memo(({detail}: {detail: ApprovalStewardEntryDTO}) => {
  const positionLevelCode = useCodeSync('sys.user.steward.position.level')
  // const performanceRuleCode = useCodeSync('sys.user.steward.salary.performance.rule')
  // const probationPerformanceRuleCode = useCodeSync('sys.user.steward.salary.probation.performance.rule')

  return (
    <Descriptions column={2}>
      <Descriptions.Item label={'入职管家姓名'}>{detail?.entryStewardName}</Descriptions.Item>
      <Descriptions.Item label={'手机号'}>{detail?.entryStewardMobile}</Descriptions.Item>
      <Descriptions.Item label={'职位'}>分散式管家</Descriptions.Item>
      <Descriptions.Item label={'入职日期'}>{detail?.entryDate}</Descriptions.Item>
      <Descriptions.Item label={'所属组织'}>{detail?.orgName}</Descriptions.Item>
      <Descriptions.Item label={'运营组织'}>{detail?.orgV2List?.map(item => item.value).join(',')}</Descriptions.Item>
      <Descriptions.Item label={'所属站点'}>
        {detail?.warehouseList?.map(item => item.value)?.join(',')}
      </Descriptions.Item>
      <Descriptions.Item label={'虚拟号码'}>{detail?.virtualNumber}</Descriptions.Item>
      <Descriptions.Item label={'带教老师'}>{detail?.teacherName}</Descriptions.Item>
      <Descriptions.Item label={'服务标签'}>
        {detail?.serviceAppList?.map(item => item.value)?.join(',')}
      </Descriptions.Item>
      <Descriptions.Item label={'岗位等级'}>{positionLevelCode[detail?.positionLevel]}</Descriptions.Item>
      <Descriptions.Item
        label={'试用期薪资'}
      >{`${detail?.salaryAndPerformance?.probationSalary}元（基础${detail?.salaryAndPerformance?.probationBaseSalary}元，绩效${detail?.salaryAndPerformance?.probationPerformance}元，津贴${detail?.salaryAndPerformance?.probationAllowance}元）`}</Descriptions.Item>
      <Descriptions.Item
        label={'转正薪资'}
      >{`${detail?.salaryAndPerformance?.salary}元（基础${detail?.salaryAndPerformance?.baseSalary}元，绩效${detail?.salaryAndPerformance?.performance}元，津贴${detail?.salaryAndPerformance?.allowance}元）`}</Descriptions.Item>
      {/*<Descriptions.Item label={'综合薪资'}>{`${detail?.salaryAndPerformance?.salary ?? ''}元`}</Descriptions.Item>
      <Descriptions.Item label={'绩效计算规则'}>
        {`${performanceRuleCode[detail?.salaryAndPerformance?.performanceRule]}：${
          detail?.salaryAndPerformance?.performanceRuleBase
        }${
          (detail?.salaryAndPerformance?.performanceRule === '1' && '%') ||
          (detail?.salaryAndPerformance?.performanceRule === '2' && '元')
        }`}
      </Descriptions.Item>
      <Descriptions.Item label={'底薪（元）'}>{`${detail?.salaryAndPerformance?.baseSalary}元`}</Descriptions.Item>
      <Descriptions.Item label={'绩效（元）'}>{`${detail?.salaryAndPerformance?.performance}元`}</Descriptions.Item>
      <Descriptions.Item label={'试用期是否打折'}>
        {`${probationPerformanceRuleCode[detail?.salaryAndPerformance?.probationPerformanceRule]}${
          detail?.salaryAndPerformance?.probationPerformanceRuleBase ?? ''
        }${
          (detail?.salaryAndPerformance?.probationPerformanceRule === '2' && '%') ||
          (detail?.salaryAndPerformance?.probationPerformanceRule === '3' && '元') ||
          (detail?.salaryAndPerformance?.probationPerformanceRule === '1' && '')
        }`}
      </Descriptions.Item>
      <Descriptions.Item
        label={'试用期工资（元）'}
      >{`${detail?.salaryAndPerformance?.probationSalary}元`}</Descriptions.Item>
      <Descriptions.Item
        label={'试用期底薪（元）'}
      >{`${detail?.salaryAndPerformance?.probationBaseSalary}元`}</Descriptions.Item>
      <Descriptions.Item
        label={'试用期绩效（元）'}
      >{`${detail?.salaryAndPerformance?.probationPerformance}元`}</Descriptions.Item>*/}
      <Descriptions.Item label={'试用期餐补（元/天）'}>
        {detail?.salaryAndPerformance?.probationMealSubsidy}
      </Descriptions.Item>
      <Descriptions.Item label={'转正后餐补（元/天）'}>{detail?.salaryAndPerformance?.mealSubsidy}</Descriptions.Item>
      <Descriptions.Item label={'绩效方案'}>
        {detail?.planList?.map(item => item.planName)?.join(',')}
      </Descriptions.Item>
      <Descriptions.Item label={'社保缴费区域'}>{detail?.socialSecurityArea?.value}</Descriptions.Item>
      <Descriptions.Item label={'用工方式'}>{detail?.employmentMode}</Descriptions.Item>
      <Descriptions.Item label={'休息日'}>{restDayCode[detail?.restDay]}</Descriptions.Item>
      <Descriptions.Item label={'开始排休日期'}>{detail?.startRestDate}</Descriptions.Item>
      <Descriptions.Item label={'贝壳用户ID'}>{detail?.beikeId}</Descriptions.Item>
      <Descriptions.Item label={'身份证号'}>{detail?.idCardNo}</Descriptions.Item>
      <Descriptions.Item label={'身份证照片'}>
        <Album
          disabled
          fileList={[detail?.idCardFrontUrl, detail?.idCardBackUrl, detail?.idCardHandUrl]
            .filter(item => item)
            .map(item => ({
              uid: item,
              url: getImg(item),
            }))}
        >
          {null}
        </Album>
      </Descriptions.Item>
      {/*<Descriptions.Item label={'个人廉洁自律承诺书'}>*/}
      {/*  <Album disabled fileList={[{uid: detail?.honestCommitmentUrl, url: getImg(detail?.honestCommitmentUrl)}]}>*/}
      {/*    {null}*/}
      {/*  </Album>*/}
      {/*</Descriptions.Item>*/}
      {/*<Descriptions.Item label={'履历真实承诺书'}>*/}
      {/*  <Album disabled fileList={[{uid: detail?.resumeCommitmentUrl, url: getImg(detail?.resumeCommitmentUrl)}]}>*/}
      {/*    {null}*/}
      {/*  </Album>*/}
      {/*</Descriptions.Item>*/}
      {/*<Descriptions.Item label={'牛油果管家管理规范'}>*/}
      {/*  <Album disabled fileList={detail?.managementSpecificationUrl?.map(item => ({uid: item, url: getImg(item)}))}>*/}
      {/*    {null}*/}
      {/*  </Album>*/}
      {/*</Descriptions.Item>*/}
      {/*<Descriptions.Item label={'薪酬保密制度'}>*/}
      {/*  <Album*/}
      {/*    disabled*/}
      {/*    fileList={[{uid: detail?.salaryConfidentialityUrl, url: getImg(detail?.salaryConfidentialityUrl)}]}*/}
      {/*  >*/}
      {/*    {null}*/}
      {/*  </Album>*/}
      {/*</Descriptions.Item>*/}
      {/*<Descriptions.Item label={'翊帮人员工手册'}>*/}
      {/*  <Album disabled fileList={detail?.employeeHandbookUrl?.map(item => ({uid: item, url: getImg(item)}))}>*/}
      {/*    {null}*/}
      {/*  </Album>*/}
      {/*</Descriptions.Item>*/}
      {/*<Descriptions.Item label={'翊帮人《考勤管理制度》'}>*/}
      {/*  <Album disabled fileList={detail?.attendanceManagementUrl?.map(item => ({uid: item, url: getImg(item)}))}>*/}
      {/*    {null}*/}
      {/*  </Album>*/}
      {/*</Descriptions.Item>*/}
      {/*<Descriptions.Item label={'员工保密协议'}>*/}
      {/*  <Album*/}
      {/*    disabled*/}
      {/*    fileList={[*/}
      {/*      {*/}
      {/*        uid: detail?.employeeConfidentialityAgreementUrl,*/}
      {/*        url: getImg(detail?.employeeConfidentialityAgreementUrl),*/}
      {/*      },*/}
      {/*    ]}*/}
      {/*  >*/}
      {/*    {null}*/}
      {/*  </Album>*/}
      {/*</Descriptions.Item>*/}
      <Descriptions.Item label={'员工入职登记表'}>
        <Album
          disabled
          fileList={[{uid: detail?.employeeEntryRegistrationUrl, url: getImg(detail?.employeeEntryRegistrationUrl)}]}
        >
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'银行卡照片'}>
        <Album
          disabled
          fileList={[
            {uid: detail?.bankCardFrontUrl, url: getImg(detail?.bankCardFrontUrl)},
            {uid: detail?.bankCardBackUrl, url: getImg(detail?.bankCardBackUrl)},
          ]}
        >
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'开户支行'}>{detail?.bankBranch ?? '/'}</Descriptions.Item>
      <Descriptions.Item label={'体检报告'}>
        <Album disabled fileList={[{uid: detail?.medicalReportUrl, url: getImg(detail?.medicalReportUrl)}]}>
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'绩效薪资确认表'}>
        <Album
          disabled
          fileList={[
            {uid: detail?.performanceSalaryConfirmationUrl, url: getImg(detail?.performanceSalaryConfirmationUrl)},
          ]}
        >
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'申请备注'}>{detail?.applyRemark ?? '/'}</Descriptions.Item>
    </Descriptions>
  )
})

// 新开站点
const NewSiteDetail = React.memo(({detail}: {detail: ApprovalNewWarehouseDTO}) => {
  return (
    <Descriptions column={2}>
      <Descriptions.Item label={'原站点'}>{detail?.oldWarehouseName}</Descriptions.Item>
      <Descriptions.Item label={'新站点名称'}>{detail?.newWarehouseName}</Descriptions.Item>
      <Descriptions.Item label={'站点地址'}>
        {[
          detail?.warehouseProvinceName,
          detail?.warehouseCityName,
          detail?.warehouseDistrictName,
          detail?.warehouseBizCircleName,
        ]
          .filter(Boolean)
          .join('-')}
      </Descriptions.Item>
      <Descriptions.Item label={'地图选点'}>{`${detail?.lat},${detail?.lng}`}</Descriptions.Item>
      <Descriptions.Item label={'详细地址'}>{detail?.warehouseAddress}</Descriptions.Item>
      <Descriptions.Item label={'开业日期'}>{detail?.openDate}</Descriptions.Item>
      <Descriptions.Item label={'仓库关联组织'}>
        {detail?.orgList?.map(item => item.value)?.join(',')}
      </Descriptions.Item>
      <Descriptions.Item label={'人员管理组织'}>{detail?.personnelOrgName}</Descriptions.Item>
      <Descriptions.Item label={'站点管理人'}>{detail?.warehouseManagerName}</Descriptions.Item>
      <Descriptions.Item label={'房源接管人'}>{detail?.propertyTakeOverName}</Descriptions.Item>
      <Descriptions.Item label={'申请附件'}>
        {detail?.attachList?.length ? (
          <Album disabled fileList={detail?.attachList.map(value => ({uid: value, url: getImg(value)}))}>
            {null}
          </Album>
        ) : (
          '/'
        )}
      </Descriptions.Item>
      <Descriptions.Item label={'申请备注'}>{detail?.applyRemark}</Descriptions.Item>
    </Descriptions>
  )
})

// 转正审批
const BecomeWorker = React.memo(({detail}: {detail: ApprovalStewardRegularDTO}) => {
  return (
    <Descriptions column={2}>
      <Descriptions.Item label={'城市'}>{detail?.cityName}</Descriptions.Item>
      <Descriptions.Item label={'转正人员'}>{detail?.userName}</Descriptions.Item>
      <Descriptions.Item label={'入职日期'}>{detail?.entryDate}</Descriptions.Item>
      <Descriptions.Item label={'转正日期'}>{detail?.regularDate}</Descriptions.Item>
      <Descriptions.Item label={'是否开通采购权限'}>
        {detail?.purchaseAuthority === '1' ? '是' : '否'}
      </Descriptions.Item>
      <Descriptions.Item label={'转正审核表'}>
        <Album fileList={detail?.regularAssessmentImgList?.map(item => ({url: getImg(item), uid: item}))} disabled>
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'实操图片'}>
        <Album fileList={detail?.practicalOperationImgList?.map(item => ({url: getImg(item), uid: item}))} disabled>
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'实操视频'}>
        <Album fileList={detail?.practicalOperationVideoList?.map(item => ({url: getImg(item), uid: item}))} disabled>
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'转正理由'}>{detail?.regularReason}</Descriptions.Item>
    </Descriptions>
  )
})

// 龙湖报价
const LhQuotationDetail = React.memo(({detail}: {detail: ApprovalStewardOrderQuotationDTO}) => {
  const charge = detail.itemList?.reduce((acc, item) => {
    acc += item.feeItemPrice * item.feeItemNum
    return acc
  }, 0)
  return (
    <>
      <Descriptions column={2}>
        <Descriptions.Item label={'订单编号'}>{detail?.orderId}</Descriptions.Item>
        <Descriptions.Item label={'总费用（元）'}>{charge}</Descriptions.Item>
        <Descriptions.Item label={'待付费维修场景图片'}>
          <Album fileList={detail.pics?.map(item => ({uid: item, url: getImg(item)}))} disabled>
            {null}
          </Album>
        </Descriptions.Item>
        <Descriptions.Item label={'付费说明'}>{detail?.feeExplain}</Descriptions.Item>
        <Descriptions.Item label={'维修地址'}>{detail?.repairAddress}</Descriptions.Item>
      </Descriptions>
      {detail.itemList?.map((item, index) => (
        <React.Fragment key={index}>
          <DividerLine title={`服务项目${index + 1}`} bottom={10} top={10} />
          <Descriptions column={2}>
            <Descriptions.Item label={'服务名称'}>{item.feeItemName}</Descriptions.Item>
            <Descriptions.Item label={'单位'}>{item.feeItemUnit}</Descriptions.Item>
            <Descriptions.Item label={'服务收费（元）'}>{item.feeItemPrice}</Descriptions.Item>
            <Descriptions.Item label={'工程量'}>{item.feeItemNum}</Descriptions.Item>
            <Descriptions.Item label={'工程量图片'}>
              <Album fileList={[{url: getImg(item.feeItemPic), uid: item.feeItemPic}]} disabled>
                {null}
              </Album>
            </Descriptions.Item>
          </Descriptions>
        </React.Fragment>
      ))}
    </>
  )
})

//贝壳报价
const BKQuotationDetail = React.memo(({detail}: {detail: ApprovalStewardOrderBKQuotationDTO}) => {
  return (
    <Descriptions column={2}>
      <Descriptions.Item label={'订单编号'}>{detail?.orderId}</Descriptions.Item>
      <Descriptions.Item label={'维修地址'}>{detail?.repairAddress}</Descriptions.Item>
      <Descriptions.Item label={'贝壳服务单号'}>{detail?.beikeOrderId}</Descriptions.Item>
      <Descriptions.Item label={'维修方案'}>{detail?.repairScheme}</Descriptions.Item>
      <Descriptions.Item label={'物品年限'}>{detail?.goodsAge}</Descriptions.Item>
      <Descriptions.Item label={'支付方式'}>
        {detail?.payTypeList?.map(item => item.value)?.join(',')}
      </Descriptions.Item>
      {detail?.payTypeList?.some(item => item.key === '1') && (
        <Descriptions.Item label={'月结费用（元）'}>{detail?.monthFee}</Descriptions.Item>
      )}
      {detail?.payTypeList?.some(item => item.key === '2') && (
        <Descriptions.Item label={'房东支付费用（元）'}>{detail?.lessorFee}</Descriptions.Item>
      )}
      <Descriptions.Item label={'总费用（元）'}>{detail?.feeTotal}</Descriptions.Item>
      <Descriptions.Item label={'资管'}>{detail?.assetManagerName}</Descriptions.Item>
      <Descriptions.Item label={'申请附件'}>
        <Album fileList={detail.pics?.map(item => ({uid: item, url: getImg(item)}))} disabled>
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'申请备注'}>{detail?.remark ?? '/'}</Descriptions.Item>
    </Descriptions>
  )
})

// 管家调薪
const StewardSalaryDetail = React.memo(({detail}: {detail: ApprovalStewardSalaryAdjustmentDTO}) => {
  // const performanceRuleCode = useCodeSync('sys.user.steward.salary.performance.rule')
  const currentSalary = detail?.currentSalaryAndPerformance
  const afterSalary = detail?.afterSalaryAndPerformance

  return (
    <Descriptions column={2}>
      <Descriptions.Item label={'调整管家'}>{`${detail?.stewardName}（${detail?.stewardMobile}）`}</Descriptions.Item>
      <Descriptions.Item label={'身份证号'}>{detail?.stewardIdCard}</Descriptions.Item>
      <Descriptions.Item label={'入职日期'}>{detail?.entryDate}</Descriptions.Item>
      <Descriptions.Item
        label={'调整前工资'}
      >{`${currentSalary?.salary}元（基础${currentSalary?.baseSalary}元，绩效${currentSalary?.performance}元，津贴${currentSalary?.allowance}元）`}</Descriptions.Item>
      <Descriptions.Item
        label={'调整后工资'}
      >{`${afterSalary?.salary}元（基础${afterSalary?.baseSalary}元，绩效${afterSalary?.performance}元，津贴${afterSalary?.allowance}元）`}</Descriptions.Item>
      {/*<Descriptions.Item label={'当前综合薪资（元）'}>{detail?.currentSalaryAndPerformance?.salary}</Descriptions.Item>
      <Descriptions.Item label={'当前绩效计算规则'}>
        {`${performanceRuleCode[detail?.currentSalaryAndPerformance?.performanceRule]}（${
          detail?.currentSalaryAndPerformance?.performanceRuleBase
        }${
          (detail?.currentSalaryAndPerformance?.performanceRule === '1' ? '%' : '') ||
          (detail?.currentSalaryAndPerformance?.performanceRule === '2' ? '元' : '')
        }）`}
      </Descriptions.Item>
      <Descriptions.Item label={'当前底薪（元）'}>
        {detail?.currentSalaryAndPerformance?.baseSalary || 0}
      </Descriptions.Item>
      <Descriptions.Item label={'当前绩效（元）'}>
        {detail?.currentSalaryAndPerformance?.performance || 0}
      </Descriptions.Item>
      <Descriptions.Item label={'调整后综合薪资（元）'}>{detail?.afterSalaryAndPerformance?.salary}</Descriptions.Item>
      <Descriptions.Item label={'调整后绩效计算规则'}>
        {`${performanceRuleCode[detail?.afterSalaryAndPerformance?.performanceRule]}（${
          detail?.afterSalaryAndPerformance?.performanceRuleBase
        }${
          (detail?.afterSalaryAndPerformance?.performanceRule === '1' ? '%' : '') ||
          (detail?.afterSalaryAndPerformance?.performanceRule === '2' ? '元' : '')
        }）`}
      </Descriptions.Item>
      <Descriptions.Item label={'调整后底薪（元）'}>
        {detail?.afterSalaryAndPerformance?.baseSalary || 0}
      </Descriptions.Item>
      <Descriptions.Item label={'调整后绩效（元）'}>
        {detail?.afterSalaryAndPerformance?.performance || 0}
      </Descriptions.Item>*/}
      <Descriptions.Item label={'生效年月'}>
        {detail?.effectiveDate ? moment(detail?.effectiveDate).format('YYYY-MM') : ''}
      </Descriptions.Item>
      <Descriptions.Item label={'申请附件'}>
        <Album fileList={detail.attachmentList?.map(item => ({uid: item, url: getImg(item)}))} disabled>
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'申请备注'}>{detail?.remark}</Descriptions.Item>
      {!!detail?.sign && (
        <Descriptions.Item label={'调整管家本人签名'}>
          <Album fileList={[{uid: detail.sign, url: getImg(detail.sign)}]} disabled>
            {null}
          </Album>
        </Descriptions.Item>
      )}
    </Descriptions>
  )
})

// 管家调动
const StewardMove = React.memo(({detail}: {detail: ApprovalStewardTransferDTO}) => {
  const positionLevelCode = useCodeSync('sys.user.steward.position.level')
  return (
    <Descriptions column={2}>
      <Descriptions.Item label={'调动类型'}>{detail?.transferType === '1' ? '短期' : '长期'}</Descriptions.Item>
      <Descriptions.Item label={'调动开始日期'}>{detail?.transferBeginDate}</Descriptions.Item>
      <Descriptions.Item label={'调动结束日期'}>{detail?.transferEndDate ?? '/'}</Descriptions.Item>
      <Descriptions.Item
        label={'调动管家'}
      >{`${detail?.transferStewardName}（${detail.transferStewardMobile}）`}</Descriptions.Item>
      <Descriptions.Item label={'当前站点'}>
        {detail?.currentWarehouseList?.map(item => item.value).join(',')}
      </Descriptions.Item>
      <Descriptions.Item label={'服务标签'}>{detail?.serviceAppName}</Descriptions.Item>
      <Descriptions.Item label={'岗位等级'}>{positionLevelCode[detail?.positionLevel]}</Descriptions.Item>
      <Descriptions.Item label={'直属上级'}>{detail.directSuperiorName}</Descriptions.Item>
      <Descriptions.Item label={'新站点'}>
        {detail?.newWarehouseList?.map(item => item.value).join(',')}
      </Descriptions.Item>
      <Descriptions.Item label={'物品归还站点'}>{detail?.returnWarehouseName}</Descriptions.Item>
      <Descriptions.Item label={'已绑房源处理'}>{detail.propertyDispose === '1' ? '不处理' : '转绑'}</Descriptions.Item>
      {detail.propertyDispose === '2' && (
        <Descriptions.Item label={'房源接手管家'}>{detail?.propertyTakeOverStewardName}</Descriptions.Item>
      )}
      <Descriptions.Item label={'新绩效方案'}>
        {detail?.newSalaryPlanList?.map(item => item.planName).join(',')}
      </Descriptions.Item>
      <Descriptions.Item label={'申请附件'}>
        <Album fileList={detail.applyAttachmentList?.map(item => ({uid: item, url: getImg(item)}))} disabled>
          {null}
        </Album>
      </Descriptions.Item>
      <Descriptions.Item label={'申请备注'}>{detail?.applyRemark}</Descriptions.Item>
      {!!detail?.sign && (
        <Descriptions.Item label={'调整管家本人签名'}>
          <Album fileList={[{uid: detail.sign, url: getImg(detail.sign)}]} disabled>
            {null}
          </Album>
        </Descriptions.Item>
      )}
    </Descriptions>
  )
})

// 班休调整
const StewardSchedulingAdjustment = React.memo(({detail}: {detail: ApprovalStewardRestAdjustmentDTO}) => {
  const adjustTypeCode = useCodeSync('steward.transfer.adjustType')
  const vacationTypeCode = useCodeSync('steward.transfer.vacationType')
  return (
    <>
      <Descriptions column={1}>
        <Descriptions.Item label={'调整类型'}>{adjustTypeCode[detail?.adjustTypeCode]}</Descriptions.Item>
      </Descriptions>
      <Descriptions column={1}>
        {detail?.adjustTypeCode === '1' && (
          <Descriptions.Item>
            <Table
              rowKey={'adjustDate'}
              dataSource={detail?.dayList}
              columns={[
                {title: '调整日期', dataIndex: 'adjustDate'},
                {title: '当前班休', dataIndex: 'currentStatus', render: value => vacationTypeCode[value]},
                {title: '目标班休', dataIndex: 'targetStatus', render: value => vacationTypeCode[value]},
              ]}
              pagination={false}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
      <Descriptions column={2}>
        {detail?.adjustTypeCode === '2' && (
          <>
            <Descriptions.Item label={'调整范围'}>
              {[detail?.beginDate, detail?.endDate].filter(Boolean).join('至')}
            </Descriptions.Item>
            <Descriptions.Item label={'休息日'}>{restDayCode[detail.restWeekDay]}</Descriptions.Item>
          </>
        )}
        <Descriptions.Item label={'附件'}>
          {detail?.attachUrlList?.length ? (
            <Album disabled fileList={detail?.attachUrlList.map(item => ({uid: item, url: getImg(item)}))}>
              {null}
            </Album>
          ) : (
            '/'
          )}
        </Descriptions.Item>
        <Descriptions.Item label={'备注'}>{detail?.remark}</Descriptions.Item>
      </Descriptions>
    </>
  )
})

const Dot = React.memo<{active?: boolean}>(props => (
  <div
    style={{
      display: 'inline-block',
      width: 16,
      height: 16,
      borderRadius: 16 / 2,
      backgroundColor: props.active ? '#00B7AE' : '#D8D8D8',
    }}
  />
))

// 输入是否为空: null, undefined, ''
export function isNonInput(value): boolean {
  // 判断 value 是否是 null
  function isNull(v) {
    return v === null
  }

  // 判断 value 是否是 undefined
  function isUndefined(v) {
    return v === undefined
  }

  // 判断 value 是否是 ''
  function isEmptyString(v) {
    return v === ''
  }

  return isNull(value) || isUndefined(value) || isEmptyString(value)
}

// 字符串转数字
export function stringToNumber(value): number {
  const result = Number(value)
  return Number.isNaN(result) ? 0 : result
}

// 保留两位小数
export function numberToFractional(value: number): number {
  if (isNonInput(value)) return 0
  return stringToNumber(value.toFixed(2))
}
